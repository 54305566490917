<template lang="pug">
.new-campaign-dc-flow-verification
  Layout(@back="$router.go(-1)" vertical)
    img.new-campaign-dc-flow-verification-monkie(
      :src="require('@/assets/admin/svg/monk_laptop_2.svg')"
    )
    om-heading.new-campaign-dc-flow-verification-title(h2) {{ $t('newCampaignFlow.dc.verification.title') }}
    transition(name="fade" mode="out-in")
      .new-campaign-dc-flow-verification-inflight.d-flex.align-items-center.flex-column(
        v-if="isVerifying"
      )
        om-button.py-2(ghost loading iconOnly large)
        om-body-text(
          bt400md
          v-html="$t('newCampaignFlow.dc.verification.inflight.description', { domain: url.domain })"
        )
      .new-campaign-dc-flow-verification-insert.d-flex.align-items-center.flex-column(
        v-else-if="needInsert"
      )
        om-body-text.mt-4(
          bt400md
          v-html="$t('newCampaignFlow.dc.verification.fail.description', { domain: url.domain })"
        )
        .d-flex.mt-4
          om-button.mr-3(ghost @click="cancel") {{ $t('cancel') }}
          om-button(primary @click="startCodeInsert") {{ $t('codeInsertV2.titles.connect') }}
      .new-campaign-dc-flow-verification-done.d-flex.align-items-center.flex-column.text-center(
        v-else
      )
        UilCheckCircle.mt-4(size="2em")
        om-body-text.mt-2(bt400md v-html="$t('newCampaignFlow.dc.verification.done.description')")
</template>

<script>
  import moment from 'moment';
  import { mapGetters, mapState } from 'vuex';
  import { get as _get } from 'lodash-es';
  import { UilCheckCircle } from '@iconscout/vue-unicons';
  import urlModsMixin from '@/mixins/urlMods';
  import GET_SITE_INFO from '@/graphql/GetSiteInfo.gql';
  import CREATE_CAMPAIGN from '@/graphql/CreateCampaign.gql';
  import { createDCUrl } from '@/utils/pncURLBuilder';
  import { getRedirectDomain } from '@/utils/dcHelpers';

  export default {
    components: {
      UilCheckCircle,
      Layout: () => import('@/components/NewCampaignFlow/Layout.vue'),
    },
    mixins: [urlModsMixin],
    data: () => ({ needInsert: null, loading: true }),
    computed: {
      ...mapState(['account']),
      ...mapGetters(['databaseId', 'domains', 'isSuperAdmin']),
      type() {
        return this.$route.query?.type ?? 'abTest';
      },
      selectedDomain() {
        const search = (url) => this.domains.find(({ domain }) => domain === url);
        let selectedDomain;

        if (this.url.domain.includes('www.')) {
          selectedDomain = search(this.url.domain.replace('www.', ''));
        }

        if (!selectedDomain) {
          selectedDomain = search(this.url.domain);
        }

        return selectedDomain;
      },
      isShopifyDomain() {
        return this.siteInfo?.engine === 'shopify';
      },
      isVerifying() {
        return this.$apollo.loading || this.loading;
      },
      isRecentlyVisitedDomain() {
        const lastRequested = this.selectedDomain?.v3LastRequestDate;

        if (!lastRequested) return false;

        const yesterdayMidnight = moment().startOf('day').subtract(1, 'day');
        const date = moment(lastRequested);

        return date.isAfter(yesterdayMidnight);
      },
    },
    watch: {
      isRecentlyVisitedDomain(value) {
        this.loading = false;
        if (value) {
          this.saveCampaignRaw();
        }
      },
    },
    apollo: {
      siteInfo: {
        query: GET_SITE_INFO,
        variables() {
          return {
            url: this.$route.query.url,
          };
        },
        update(data) {
          return data.getSiteInfo;
        },
        skip() {
          return this.isRecentlyVisitedDomain;
        },
        result() {
          this.handleResults();
        },
      },
    },
    mounted() {
      this.setCampaignDomain(this.$route.query.url);
    },
    methods: {
      startCodeInsert() {
        this.$router.push({
          name: 'code-insert-domain',
          query: { create: this.type, url: this.$route.query.url, domain: this.url.domain },
        });
      },
      cancel() {
        const { initiator = 'dashboard' } = this.$route.query;
        this.$router.push({ name: initiator });
      },
      async handleResults() {
        this.loading = false;
        if (!this.isShopifyDomain && this.siteInfo?.om !== this.databaseId) {
          this.needInsert = true;
        } else {
          await this.saveCampaignRaw();
        }
      },
      async saveCampaignRaw() {
        if (!this.selectedDomain) return;

        try {
          const response = await this.$apollo.mutate({
            mutation: CREATE_CAMPAIGN,
            variables: {
              input: {
                name: '',
                domainId: this.selectedDomain._id,
                templateId: null,
                dynamicContent: true,
                addControlVariant: this.type === 'abTest',
              },
            },
          });

          const campaignId = _get(response, 'data.createCampaign.id');
          const variantId = _get(response, 'data.createCampaign.variants.0._id');

          if (!campaignId || !variantId) {
            throw new Error('Failed to create campaign');
          }

          const [variant] = _get(response, 'data.createCampaign.variants');
          const campaignDomain = _get(response, 'data.createCampaign.domain');
          const url = this.getDynamicContentUrl(
            getRedirectDomain(this.url.gotUrl, campaignDomain),
            campaignId,
            this.$i18n.locale,
            variant._id,
            this.url.path ?? '',
          );

          window.open(url, '_self');
        } catch (e) {
          console.log(e);
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError'),
          });
        }
      },
      getDynamicContentUrl(campaignDomain, campaignId, locale, variantId, pathName) {
        return createDCUrl({
          domain: campaignDomain,
          campaignId,
          locale,
          variantId,
          path: pathName,
          returnUrl: `/${this.account.databaseId}/campaign/${campaignId}/settings?activeBox=condition`,
          backUrl: `/${this.account.databaseId}/campaign/${campaignId}`,
          isNew: true,
          isSuperAdmin: this.isSuperAdmin,
          featureMode: null,
        });
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .new-campaign-dc-flow-verification
    &-monkie
      max-width: 16.25rem
      margin-top: 5rem
    &-title
      margin-top: 2.5rem
    &-inflight,
    &-insert
      max-width: 18.75rem
      text-align: center
      img
        margin-bottom: 1rem
    &-done
      svg
        color: $om-ds-green
</style>
